import React from 'react'

import theme from '../../theme/theme'

class MyDonationChoices extends React.Component {
	componentDidMount() {
		window.location.replace(theme.redirects.myDonationChoices + '/' + window.location.search)
	}

	render () {
		return true
	}
}

export default MyDonationChoices
